.root{
    width: 100%;
}

.logo{
    flex-grow: 1;
}

.content{
    width: 100%;
}

.contentShift{

    margin-left: 240;
}

.drawer{
    background: linear-gradient(180deg, rgba(39, 125, 204, 1) 35%, rgba(0, 237, 255, 1) 100%);
    width: 240px;
}